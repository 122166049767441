@media(max-width: 1500px) {
    footer {
        bottom: 30px;
    }
    .footer_bottom {
        max-width: 1140px;
      }
}
@media(max-width: 1400px) {
    .footer_bottom_link_menu {
        margin-right: 50px;
    }
    .copyright_right {
        margin-left: 0px;
    }
}

.MuiTabScrollButton-root {
    color: #fff !important;
}

@media only screen and (max-width: 1200px) {
    .nftMain .stakebtnarea {

        flex: 0 0 70%;
    }

    .gifbox {
        max-width: 280px;
    }

    .nftMain .popular_gaming_inner .nftsvideo .itembox {
        flex: 0 0 35%;
    }

    .listViewBx {
        margin: 40px auto;
    }
    footer {
        bottom: 20px;
    }
    .footer_social li {
        padding-right: 11px;
        margin-right: 10px;
    }
  

}

@media only screen and (max-width: 992px) {

    .test {
        flex-basis: calc(50% - 20px) !important;
    }

    footer {
        bottom: 95px;
    }
    .footer_bottom_inner {
        flex-direction: column;
        text-align: center;
    }
    .copyright_right {
        margin-bottom: 12px;
    }

    .logo {
        text-align: center !important;
    }

    .boxbtn {
        font-size: 15px;
    }

    .nftMain .popular_gaming_inner .nftsvideo {
        flex-direction: column;
    }

    .listViewBx {
        margin: 40px auto;
    }


    .lazyload-wrapper {
        // max-width: 100%;
        width: 100%;
        height: auto;
        // margin: 18%;

    }

    .topInformation h1 {
        font-size: 35px;
        text-align: center;
    }

    .Zebheading {
        text-align: center;
        margin-top: 10px;
    }

    .tabbar {
        justify-content: center;
    }

    // .nftMain .stakebtnarea .inputvalue {
    //     justify-content: center;
    //     column-gap: 40px;
    // }
    .inputbox {
        justify-content: center;
    }

    .nftMain .popular_gaming_inner .notearea {
        max-width: auto;
    }

    .buttonGroup {
        justify-content: center;
    }


    // .informationMU .infotxt h3 {
    //     font-size: 32px;
    // }
    .informationMU .benefitcontent {
        padding: 20px 0;
    }

    .nftMain {
        padding: 0 40px;
    }

    .css-m9glnp-MuiPaper-root-MuiDialog-paper {
        width: 80% !important;
        height: 50% !important;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 150%;
    }
    .footer_bottom_link_menu {
        margin-right: 0px;
    }
   
}

@media (max-width: 767px) {

    .loader__container__counter {
        font-size: 100px;
    }

    .loader__container__loading {
        font-size: 35px;
    }
    .adminsec {
        margin-bottom: 150px;
    }

    header {
        top: 20px;
        padding-inline: 15px;
    }


    .test {
        flex-basis: calc(100%) !important;
    }

    .text-reveal h2 {
        font-size: 2.5rem;

    }

    .coinbox {
        max-width: 322px;
    }

    .fullwidth {
        width: 100%;
    }

    .boxbtn {
        font-size: 14px;
    }

    .listViewBx {
        margin: 0px auto;
    }

    // header .logoMainHead {
    //     width: 75px;
    // }

    .btnMU,
    .btn-btnMU {
        font-size: 14px;
    }

    .informationMU .infotxt {
        text-align: center;
    }

    .informationMU .infotxt p {
        font-size: 13px;
    }

    // .listViewBx .inrInfoBx .imginrBx {
    //     margin-bottom: 0px;
    // }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 70%;
    }

    .nftMain {
        padding: 5px;
    }

    .nftMain .stakebtn {
        padding: 4px 7px;
        border-radius: 9px;
    }


    .countDownMu p {
        font-size: 14px;
    }
    footer {
        bottom: 70px;
    }   




 

  

    // .nftBalance .balanceViewBx .balancelist {
    //     padding: 23px;
    // }
    // .nftMain {
    //     padding: 20px 0px;
    // }
}

@media (max-width: 575px) {

    .hello {
        .MuiTabs-flexContainer {
            justify-content: unset !important;

        }
    }

    .box {
        flex-direction: column;
        align-items: center;
    }

    .footer_bottom {
        padding: 10px 0;
    }

    // .nftMain .stakebtnarea .inputvalue {
    //     column-gap: 10px;
    // }
    .nftMain .stakebtnarea span {
        font-size: 16px;
    }

    .MuiDialogContent-root span {
        font-size: 16px;
    }

    .test {
        flex-basis: calc(100%) !important;
    }

    .wnbtn {
        display: none;
    }

    .mobilebtn {
        background-color: #bc4242;
        padding: 10px;
        border-Radius: 20px;
        cursor: pointer;
        margin-right: 4px;
        display: block !important;
        margin: 0 auto;
    }

    .informationMU {
        .fqbox {
            padding: 0px;
        }
    }

    //   .dollar{
    //     margin-right: -7px !important;
    //   }

    .logo b {
        font-size: 26px !important;
    }

    .boxbtn2 {
        width: fit-content;
        font-size: 13px !important;
    }

    .popular_gaming_inner .swiper-button-next,
    .popular_gaming_inner .swiper-button-prev {
        height: 35px;
        width: 35px;
    }

    .nftMain .nftheadline {
        font-size: 35px;
    }

    .topInformation h1 {
        font-size: 35px;
    }

    .news h3 {
        font-size: 35px;
    }

    .nftBalance h3 {
        font-size: 35px;
    }

    .informationMU .infotxt h3 {
        font-size: 35px;
    }



    .listViewBx.ms\:mt-5.pt-5 {
        padding-top: 0 !important;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 44vw;
    }


    .nftBalance .balanceViewBx ul li {
        margin-bottom: 15px;
        font-size: 14px;
    }


    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 14px;
    }

    .footer_bottom_link_menu ul li {
        margin-right: 0;
        padding-right: 8px;
    }

    .footer_social ul li a {
        width: 22px;
        height: 22px;
    }

    footer .copyright span {
        display: block;
        margin-top: 4px;
    }

    footer .footer {
        padding: 35px 0px 15px;
    }

    .connectWalletToggle .modal-content .modal-body {
        padding: 0px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 20px 0px;
    }

    header .header-brand img {
        max-width: 155px;
    }

    .topInformation h1 .lstwld {
        display: block;
    }

    .topInformation {
        margin-bottom: 20px;
    }

    .countDownMu {
        font-size: 35px;

        p {
            font-size: 13px;

            span {
                display: block;
            }
        }
    }

    footer .social-icons a {
        width: 40px;
        height: 40px;
        margin: 0px 5px !important;
    }

}

@media(max-width: 420px) {
    .MuiDialogContent-root span {
        font-size: 14px;
    }
}

@media only screen and (max-width: 390px) {
    .nftMain .stakebtnarea span {
        font-size: 12px;
        column-gap: 20px;
    }
    .filterclass .zebarea button, .filterclass .usdtarea button {
        padding: 8px 37px;
    }

    .nftBalance .balanceViewBx ul li {
        font-size: 12px;
    }

    // .topInformation .btnMU,
    // .topInformation .btn-btnMU {
    //     min-width: 105px;
    // }

    .header {

        .btnMU,
        .btn-btnMU {
            font-size: 15px;

            svg {
                font-size: 1rem;
            }
        }
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 60vw;
    }

    // .listViewBx .inrInfoBx img {
    //     max-width: 70px;
    // }

    .connectWalletToggle .popupbtngrp button img,
    .connectWalletToggle .popupbtngrp a img {
        height: 40px;
        width: 40px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 12px;
        padding: 10px 4px 10px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 15px 10px;
    }

    .connectWalletToggle .modal-content .modal-title {
        font-size: 18px;
    }

    header .header-brand img {
        max-width: 125px;
    }

    .header {

        .btnMU span,
        .btn-btnMU span {
            display: block;
            padding: 0px 6px;
            font-size: 10px;
        }
    }



}

@media only screen and (min-width:767px) {
    .popular_gaming_inner>.swiper .swiper-slide.swiper-slide-next {
        transform: scale(1.2);
        position: relative;
        z-index: 9
    }

    .popular_gaming_inner>.swiper {
        transform: scale(.95);
        padding-top: 60px;
        padding-bottom: 90px;
        padding-left: 30px;
        padding-right: 30px
    }
}